.signupbutton {
  float: right;
  margin-top: 0px;
  background-color: #ff6c5e;
  border-color: #ff6c5e;
  border-radius: 15px;
  &:hover {
    border-color: #ff6c5e;
    background-color: #ff6c5e;
  }
  &:active {
    border-color: #ff6c5e;
    background-color: #ff6c5e;
  }
}
.media-home {
  margin-top: 50px;
  margin-left: 2vw;
  font-size: 25px;
  @media (max-width: 1024px) {
    font-size: 25px;
    line-height: 25px;
  }

  // @media (min-width: 1025px) and(max-width: 1280px) {
  //   margin-left: 2%;
  // }
  // @media (min-width: 1280px) and (max-width: 1300px) {
  //   margin-left: 2%;
  // }
  // @media (min-width: 1300px) and (max-width: 1400px) {
  //   margin-left: 2%;
  // }
  // @media (min-width: 1401px) and (max-width: 1500px) {
  //   margin-left: 2%;
  // }
  // @media (min-width: 1500px) and (max-width: 1800px) {
  //   margin-left: 6%;
  // }
  // @media (min-width: 501px) and (max-width: 767px) {
  //   margin-left: 50px;
  // }
  // @media (min-width: 768px) and (max-width: 900px) {
  //   justify-content: center;
  //   align-items: center;
  //   margin-top: 20px;
  // }
  // @media (min-width: 100px) and (max-width: 500px) {
  //   margin-left: 20px;
  //   margin-top: auto;
  // }
  // // @media (min-width: 1596px) and (max-width: 1600px) {
  // //   margin-left: 1vw;
  // // }
  // // @media (min-width: 1601px) and (max-width: 1800px) {
  // //   margin-left: 7.5vw;
  // // }
  // // // @media (min-width: 772px) and (max-width: 900px) {
  // // //   margin-left: 0vw;
  // // // }
  // // @media (min-width: 501px) and (max-width: 767px) {
  // //   margin-left: 50px;
  // // }
  // // @media (min-width: 768px) and (max-width: 900px) {
  // //   justify-content: center;
  // //   align-items: center;
  // //   margin-top:20px
  // // }
  // // @media (min-width: 100px) and (max-width: 500px) {
  // //   margin-left: 20px;
  // //   margin-top: auto;
  // // }
}

.media-home1 {
  margin-top: 50px;
  margin-left: 2vw;
  font-size: 25px;
  @media (max-width: 1024px) {
    font-size: 25px;
    line-height: 25px;
  }
  @media (max-width: 1300px) and (min-width: 1420px) {
    margin-left: 4vw;
    font-size: 45px;
  }
  @media (max-width: 1000px) and (min-width: 768px) {
    margin-left: -2vw;
    font-size: 45px;
  }
}
.media-home2 {
  margin-top: 50px;
  margin-left: 2vw;
  font-size: 25px;
  @media (max-width: 1024px) {
    font-size: 25px;
    line-height: 25px;
  }
  @media (max-width: 1300px) and (min-width: 1420px) {
    margin-left: 4vw;
    font-size: 45px;
  }
  @media (max-width: 1000px) and (min-width: 768px) {
    margin-left: -2vw;
    font-size: 45px;
  }
}
.para-home {
  font-weight: 600;
  line-height: 50px;
  font-size: 40px;
  text-align: left;
  color: black;
  margin-left: 77px;
  @media (max-width: 1024px) {
    font-size: 25px;
    line-height: 40px;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media (min-width: 1280px) and (max-width: 1366px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    margin-left: 32px;
  }
}
.para2-home {
  margin-right: 24px;
  line-height: 50px;
  font-size: 30px;
  text-align: justify;
  color: black;
  margin-top: 0.1vw;
  justify-content: center;
  @media (max-width: 1024px) {
    font-size: 15px;
    line-height: 25px;
  }
  @media (min-width: 1025px) and(max-width: 1280px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 1280px) and (max-width: 1366px) {
    font-size: 20px;
    line-height: 38px;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    font-size: 23px;
    line-height: 35px;
  }
  @media (min-width: 1440px) and (max-width: 1600px) {
    font-size: 23px;
    line-height: 41px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 10px;
  }
  @media (min-width: 501px) and (max-width: 700px) {
    margin-left: 10px;
  }
  @media (min-width: 701px) and (max-width: 800px) {
    margin-left: 10px;
  }
}
.para3-home {
  margin-right: 24px;
  line-height: 50px;
  font-size: 30px;
  text-align: left;
  color: black;
  margin-top: -1vw;
  @media (max-width: 1024px) {
    font-size: 15px;
    line-height: 25px;
  }
  @media (min-width: 1025px) and(max-width: 1280px) {
    // @media (min-width:1025 and ) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 1280px) and (max-width: 1366px) {
    font-size: 20px;
    line-height: 35px;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    font-size: 23px;
    line-height: 35px;
  }
  @media (min-width: 1440px) and (max-width: 1600px) {
    font-size: 23px;
    line-height: 41px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 10px;
  }
  @media (min-width: 501px) and (max-width: 700px) {
    margin-left: 10px;
  }
  @media (min-width: 701px) and (max-width: 800px) {
    margin-left: 10px;
  }
}
.vedio-home {
  margin-top: 35px;
  margin-left: 102px;
  @media (max-width: 1024px) {
    margin-top: 50px;
    margin-left: 50px;
  }
  @media (min-width: 1220px) and (max-width: 1366px) {
    margin-left: 50px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-top: 10px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    margin-top: 10px;
  }
}
.vedio-1 {
  float: right;
  width: 100%;
  margin-left: 25px;
  margin-top: 4vw;
  height: 100%;
  @media (min-width: 501px) and (max-width: 767px) {
    margin-left: 10px;
    width: 100%;
    padding: 10px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-right: 23px;
    width: 100%;
    padding: 10px;
  }
  @media (min-width: 901px) and (max-width: 999px) {
    margin-top: 10px;
  }
}
.image3 {
  width: 100%;
  height: 520px;
  background-size: cover;
  @media (min-width: 501px) and (max-width: 767px) {
    width: 100%;
    height: 300px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    // width: 100%;
    height: 300px;
    margin-bottom: 5%;
  }
}
.profile {
  text-align: left;
  color: black;
  line-height: 40px;
  font-size: 25px;
  @media (max-width: 1024px) {
    font-size: 15px;
    line-height: 20px;
  }
  @media (min-width: 1025px) and(max-width: 1280px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    font-size: 23px;
    line-height: 34px;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-top: 1vw;
    margin-left: 1%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 1%;
    margin-right: 23px;
  }
}
.find {
  border-radius: 50px;
  float: left;
  background-color: rgb(22, 88, 116);
  width: 200px;
  margin-left: 250px;
  margin-bottom: 77px;
  @media (min-width: 708px) and (max-width: 1400px) {
    margin-left: 25%;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-left: 12%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 12%;
  }
}
.point-1 {
  font-size: 25px;
  height: 60px;
  color: black;
  margin-left: 103px;
  @media (max-width: 1024px) {
    font-size: 15px;
    height: 60px;
    // line-height: 40px;
  }
  @media (min-width: 1025px) and(max-width: 1280px) {
    font-size: 18px;
    height: 80px;
    margin-top: 1vw;
    // line-height: 40px;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    font-size: 20px;
    height: 90px;
    // line-height: 40px;
  }
  @media (min-width: 1440px) and (max-width: 1600px) {
    font-size: 21px;
    // line-height: 40px;
  }
  @media (min-width: 1600px) and (max-width: 1680px) {
    font-size: 24px;
    // line-height: 40px;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-top: 1vw;
    margin-left: 7%;
    padding: 5px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 7%;
    padding: 5px;
    margin-top: 1vw;
  }
}
.profile-main {
  margin-left: 4%;
  margin-top: 0vw;
  padding: 36px;
  @media (min-width: 768px) and (max-width: 1280px) {
    margin-top: -3vw;
    margin-left: 1%;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-top: 1vw;
    margin-left: 1%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 1%;
    margin-right: 23px;
  }
}
.point-home {
  margin-top: 90px;
  margin-left: 140px;
  @media (max-width: 1024px) {
    margin-top: 50px;
    margin-left: 4px;
  }
}
.image1 {
  float: right;
  width: 93%;
  margin-top: -1.4vw;
  padding: 36px;
  margin-left: 25px;
  @media (min-width: 768px) and (max-width: 900px) {
    width: 100%;
    padding: 10px;
    margin-top: 2vw;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    width: 100%;
    padding: 10px;
    margin-top: 2vw;
  }
  @media (min-width: 773px) and (max-width: 890px) {
    margin-top: 0.1vw;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    width: 100%;
    padding: 10px;
    margin-top: 2vw;
  }
}
.point-head {
  font-weight: 600;
  font-size: 40px;
  color: black;
  line-height: 50px;
  // margin-top: 1vw;

  // @media (max-width: 100px) and (min-width:1199) {
  //   font-size: 20px;
  //   line-height: 30px;
  // }
  @media (min-width: 1000px) and(max-width: 1200px) {
    font-size: 25px;
    // margin-left: 2vw;
  }
  @media (min-width: 1201px) and(max-width: 1365px) {
    font-size: 25px;
    // margin-left: -20vw;
    line-height: 50px;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    font-size: 35px;
    line-height: 45px;
    //  margin-left: -5vw;
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    margin-left: -1vw;
    font-size: 35px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    font-size: 15px;
    line-height: 25px;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
  @media (min-width: 700px) and (max-width: 900px) {
    font-size: 23px;
    margin-left: -10px;
  }
  @media (min-width: 901px) and (max-width: 1000px) {
    font-size: 23px;
    margin-left: -10px;
  }
  @media (min-width: 1600px) and (max-width: 1800px) {
    // margin-left: -4%;
  }
}
.point-head2 {
  font-weight: 600;
  font-size: 40px;
  color: black;
  line-height: 50px;

  @media (min-width: 1000px) and(max-width: 1200px) {
    font-size: 25px;
    margin-left: 3vw;
  }
  @media (min-width: 1201px) and(max-width: 1367px) {
    font-size: 41px;
    margin-left: -1vw;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    font-size: 35px;
    line-height: 45px;
    // margin-left: 1vw;
  }
  @media (min-width: 1440px) and (max-width: 1600px) {
    //  margin-left: 1vw;
    font-size: 35px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    font-size: 15px;
    line-height: 25px;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
  @media (min-width: 700px) and (max-width: 900px) {
    font-size: 23px;
    margin-left: -10px;
  }
  @media (min-width: 901px) and (max-width: 1000px) {
    font-size: 23px;
    margin-left: -10px;
  }
  @media (min-width: 1600px) and (max-width: 1800px) {
    margin-left: -7%;
  }
}

.point-head1 {
  font-weight: 600;
  font-size: 40px;
  color: black;
  line-height: 50px;
  @media (min-width: 1000px) and(max-width: 1200px) {
    font-size: 30px;
    // margin-left: -20vw;
  }
  @media (min-width: 1201px) and(max-width: 1299px) {
    font-size: 30px;
    // margin-left: -20vw;
  }
  @media (min-width: 1300px) and(max-width: 1400px) {
    font-size: 35px;
  }
  @media (min-width: 1401px) and(max-width: 1600px) {
    font-size: 35px;
    margin-left: vw;
  }
  @media (min-width: 1600px) and (max-width: 1800px) {
    margin-left: -4%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    font-size: 20px;
    line-height: 25px;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    font-size: 23px;
    line-height: 25px;
    margin-top: -30px;
    margin-left: 1px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 20px;
    line-height: 25px;
    margin-left: 23px;
  }
  @media (min-width: 901px) and (max-width: 999px) {
    font-size: 25px;
    line-height: 25px;
    // margin-left: 23px;
  }
}
.point-header {
  font-weight: 600;
  font-size: 38px;
  color: black;
  line-height: 50px;
  margin-top: 2vw;
  // margin-left: 5%;

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
    margin-left: 16%;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    font-size: 20px;
    line-height: 50px;
  }
  @media (min-width: 1366px) and (max-width: 1500px) {
    font-size: 25px;
    line-height: 45px;
  }

  // @media (min-width: 768px) and (max-width: 900px) {
  //   margin-left: 20%;
  //   margin-top: -2vw;
  // }
}

.point-market {
  font-weight: 600;
  font-size: 28px;
  color: black;
  line-height: 50px;
  margin-top: 2vw;
  margin-left: 11%;
  white-space: nowrap;

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
    margin-left: 67px;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    font-size: 20px;
    line-height: 50px;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    font-size: 28px;
    line-height: 45px;
    margin-left: 65px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    margin-left: 20%;
  }
}

.point-main {
  font-weight: 600;
  font-size: 40px;
  color: black;
  line-height: 48px;

  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 1025px) and(max-width: 1280px) {
    font-size: 20px;
    line-height: 35px;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    font-size: 28px;
    line-height: 45px;
  }
}
.circl-clr {
  color: #165874;
  padding: 5px;
  font-size: 25px;
}

.search-input {
  border-radius: 30px;
  padding: 0px;
  width: 400 px;
  height: 38px;
}
.ant-input-affix-wrapper .ant-input {
  border-radius: 50px;
  // width: 400px;
  height: 40px;
  padding: 10px;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.ant-select-selection--single {
  border-radius: 30px;
  padding: 0px;
  width: 400px;
  height: 38px;
  margin-left: 0%;
  @media (max-width: 768px) {
    width: 100%;
  }
  // @media (min-width: 901px) and (max-width: 1024px) {
  //   width: 400px;
  // // margin-left: 27%;
  //   }

  // @media (min-width: 768px) and (max-width: 900px) {
  //  width:100%;
  // margin-left: 6%;
  // }
  // @media (min-width: 501px) and (max-width: 767px) {
  // width:332px;
  // }
  // @media (min-width: 100px) and (max-width: 500px) {
  // width:327px;
  // }
}
.textshadow {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.ant-card-body {
  width: 100%;

  padding: 6px;
}
.visually-hidden {
  position: absolute;
  left: -100vw;
}

input:checked ~ .btn {
  background: #165874;
  border: #165874;
  color: whitesmoke;
}
// .range-styl:checked {
//   position: absolute;
//   left: -100vw;
//   background: #165874;
//   border: #165874;
//   color: whitesmoke;
// }

.btn {
  border: 1px solid grey;
  text-align: center;
  border-radius: 30px;
  line-height: 2;
  margin-bottom: 10px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  // display: flex;}
}
.buttonView {
  margin-left: 27%;
  @media (min-width: 901px) and (max-width: 1024px) {
    margin-left: 48%;
  }

  @media (min-width: 768px) and (max-width: 900px) {
    margin-left: 47%;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    // margin-left: 38%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 38%;
  }
}
.btnServices {
  border: 1px solid grey;
  text-align: center;
  // border-radius: 30px;
  line-height: 2;
  margin-bottom: 10px;
  height: 80px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 35%;
  width: 39%;
  @media (min-width: 901px) and (max-width: 1024px) {
    margin-left: 8%;
    width: 100%;
    height: 97px;
  }

  @media (min-width: 768px) and (max-width: 900px) {
    margin-left: 8%;
    width: 100%;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    // margin-left: 38%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    width: 100%;
    margin-left: 10%;
  }
}
.signup-button {
  border-radius: 100px;
  background-color: #dce7f0;
  color: #004b84;
  width: 150px;
  border: none;
  font-weight: bold;
  height: 40px;
  margin-top: 2vw;
  margin-right: 65%;
  margin-left: 20%;
  // @media (min-width: 901px) and (max-width: 1024px) {
  // width:50%;
  // // margin-left: 24%;
  // height: 50px;
  //   }
  // @media (min-width: 768px) and (max-width: 900px) {
  //  margin-left: 43%;
  // // width:100%;
  // }
  // @media (min-width: 501px) and (max-width: 767px) {
  // // margin-left: 38%;

  // }
  @media (max-width: 767px) {
    margin-bottom: 20vw;
  }
}

.btn:hover {
  cursor: pointer;
  background: #165874;
}

.addresinput,
.css-yk16xz-control {
  width: 400px !important;
  height: 40px !important;
  border-radius: 50px !important;
}

.addresinput,
.css-1hwfws3 {
  height: 40px !important;
}
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #165874;
  border-color: #165874;
}
.ant-btn-primary {
  color: #fff;
  background-color: #165874;
  border-color: #165874;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f2f5;
}
.form-input {
  height: 50px !important;
  border-radius: 5px !important;
}
.color-grey {
  color: rgb(153, 162, 195);
}
.terms {
  font-size: 0.9vw;
  @media (min-width: 100px) and (max-width: 500px) {
    font-size: 2.9vw;
  }
}
.padding-card1-whyjoin,
.padding-card1-whyjoin-image-card {
  @media (min-width: 1681px) {
    height: 29vw;
    padding: 30px !important;
  }
  @media (min-width: 1441px) and (max-width: 1680px) {
    height: 32.5vw;
  }
  @media (min-width: 1280px) and (max-width: 1439px) {
    height: 39.5vw;
  }
  @media (min-width: 1024px) and(max-width: 1279px) {
    height: 50.5vw;
  }
  @media (min-width: 993px) and(max-width: 1023px) {
    padding: 15px !important;
  }
  @media (max-width: 992px) {
    padding: 10px !important;
  }
  @media (max-width: 500px) {
    height: 130vw;
  }
  @media (min-width: 501px) and (max-width: 991px) {
    height: 62vw;
  }
}
.padding-card1-whyjoin-image-card {
  @media (max-width: 500px) {
    height: 154vw !important;
  }
  @media (min-width: 501px) and (max-width: 991px) {
    height: 60vw !important;
  }
}
.form-input {
  height: 50px !important;
  border-radius: 5px !important;
}
.para-about {
  margin-top: 90px;

  @media (max-width: 1024px) {
    margin-top: -3px;
    line-height: 20px;
    font-size: 15px;
    margin-bottom: 150px;
  }
  @media (min-width: 1025px) and(max-width: 1280px) {
    font-size: 18px;
    line-height: 28px;
    margin-top: -9px;
    margin-bottom: 150px;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    font-size: 20px;
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 150px;
  }
  @media (min-width: 1440px) and (max-width: 1680px) {
    margin-top: 48px;
    margin-bottom: 150px;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-top: 4vw;
    margin-bottom: 150px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 4vw;
    margin-bottom: 150px;
  }
}
.padding-card1-about {
  height: 100% !important;
  @media (min-width: 100px) and (max-width: 500px) {
    height: auto;
  }
}
.background-content {
  font-size: 35px;
  line-height: 50px;
  margin-left: 50px;
  color: white;

  padding-top: 7%;
  @media (max-width: 1024px) {
    margin-left: 50px;
    line-height: 30px;
    font-size: 25px;
    margin-bottom: 50px;
  }
  @media (min-width: 1025px) and(max-width: 1280px) {
    margin-left: 50px;
    line-height: 35px;
    font-size: 30px;
    margin-bottom: 50px;
  }
  @media (min-width: 1366px) and (max-width: 1440px) {
    margin-left: 50px;
    line-height: 40px;
    font-size: 30px;
    margin-bottom: 50px;
  }
}
.service-area {
  margin-left: 27%;
  @media (max-width: 768px) {
    margin-left: 0% !important;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 20% !important;
    width: 63%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 0% !important;
  }
}
.service-range {
  margin-left: 32%;
  @media (max-width: 768px) {
    margin-left: 0% !important;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 20% !important;
    width: 63%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 0% !important;
  }
}
.internet-market {
  margin-left: 27%;
  @media (max-width: 768px) {
    margin-left: 0% !important;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 20% !important;
    width: 63%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 0% !important;
  }
}
.service-step3 {
  margin-left: 27%;
  @media (max-width: 768px) {
    margin-left: 0% !important;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 30% !important;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 1% !important;
  }
}
.show_desktop {
  @media (max-width: 993px) {
    display: none;
  }
}
.show_desktop_header {
  z-index: 4;
  @media (min-width: 994px) {
    float: right;
    display: flex;
    justify-content: end;
    margin-right: 36px;
    z-index: 4;
  }
  @media (max-width: 993px) {
    display: none;
  }
}
.show_mobile {
  @media (min-width: 992px) {
    display: none;
  }
}
.show_mobile_btn {
  border-radius: 50px;
  background-color: rgb(22, 88, 116);
  // width: 200px;
  margin-top: 20px;
  display: block;

  // @media (min-width: 767px) {
  //   display: none;
  // }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 44%;
    margin-top: 2vw;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    margin-left: 27% !important;
  }
}

.input_width {
  width: 400px;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
.input_width_state {
  width: 400px;
  @media (max-width: 375px) {
    width: 100% !important;
  }
}
.input_width1 {
  width: 400px;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
.input_modal {
  width: 400px;
  height: 40px;
  border-radius: 50px;
  padding: 4px 11px;
  @media (min-width: 1025px) and (max-width: 1280px) {
    width: 384px;
    padding: 4px;
  }
  @media (min-width: 901px) and (max-width: 1024px) {
    width: 389px;
    padding: 4px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    width: 100%;
    padding: 4px;
  }
  @media (min-width: 501px) and (max-width: 767px) {
  }
  @media (min-width: 100px) and (max-width: 500px) {
    width: 312px;
    padding: 4px 0px;
  }
}
.input_password {
  width: 402px;
  height: 40px;
  border-radius: 50px;
  padding: 2px;
  @media (min-width: 1025px) and (max-width: 1280px) {
    width: 384px;
    padding: 4px;
  }
  @media (min-width: 901px) and (max-width: 1024px) {
    width: 389px;
    padding: 4px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    width: 100%;
    padding: 4px;
  }
  @media (min-width: 501px) and (max-width: 767px) {
  }
  @media (min-width: 100px) and (max-width: 500px) {
    width: 312px;
    padding: 4px 0px;
  }
}
// .radio_btns {
//   margin-left: 46%;
//   @media (max-width: 768px) {
//     margin-left: 0% !important;
//   }
//   @media (min-width: 768px) and (max-width: 1024px) {
//     margin-left: 45% !important;
//   }
//   @media (min-width: 768px) and (max-width: 1366px) {
//     margin-left: 44% !important;
//   }
//   @media (min-width: 100px) and (max-width: 500px) {
//     margin-left: 33% !important;
//   }

// }
.text_areabox {
  border-radius: 0px;
  width: 23%;
  height: 80px;
  padding: 10px;
  @media (max-width: 768px) {
    margin-left: 0% !important;
    width: 71px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    // margin-left: 27% !important;
    width: 50%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    // margin-left: 27% !important;
    width: 66%;
  }
}
.ages_area {
  margin-left: 38%;
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 28% !important;
    width: 47%;
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    margin-left: 32%;
    // width: 47%;
  }
  @media (min-width: 768px) and (max-width: 1366px) {
    margin-left: 32%;
    // width: 47%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 18%;
    width: 67%;
  }
  @media (max-width: 768px) {
    margin-left: -13%;
    width: 53%;
  }
}
.textView {
  margin-left: 10%;
  font-weight: bold;
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 35% !important;
    // width: 30%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 0px;
  }
}
.textarea_step7 {
  border-radius: 0px;
  width: 23%;
  height: 80px;
  padding: 10px;
  @media (max-width: 768px) {
    margin-left: 0% !important;
    width: 71px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: -6%;
    width: 37%;
  }
}
.box_view {
  margin-left: 29%;
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 23%;
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    margin-left: 22%;
  }
}
.phone {
  margin-left: 55%;
  @media (min-width: 768px) and (max-width: 1280px) {
    margin-left: 67%;
  }
  @media (min-width: 768px) and (max-width: 1366px) {
    margin-left: 68%;
  }
}

.service-demo {
  margin-left: 35%;
  @media (max-width: 768px) {
    margin-left: 0% !important;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 20% !important;
    width: 63%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 1%;
    width: 92%;
  }
}

.input_step7 {
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 6%;
    width: 65%;
  }
}

.imgView {
  margin-left: 93%;
  height: 80%;
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 74%;
  }
}
.homeView {
  border-radius: 50px;
  background-color: #dce7f0;
  color: #004b84;
  width: 150px;
  border: none;
  font-weight: bold;
  height: 40px;
  margin-left: 45%;
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 28%;
  }
}
.main-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 117em;
  margin-top: 3vw;
}
.heading-copy {
  margin-top: 0em;
  font-size: 3.3em;
  line-height: 1.2;
  text-align: center;
  @media (min-width: 100px) and (max-width: 500px) {
    font-size: 1em;
  }
  @media (min-width: 501px) and (max-width: 1000px) {
    font-size: 1em;
  }
  @media (min-width: 1001px) and (max-width: 1150px) {
    font-size: 1.2em;
  }
}
.para2-market {
  line-height: 1.2;
  font-size: 1.8em;
  padding: 18px;
  @media (min-width: 1024px) and (max-width: 1280px) {
    line-height: 1.2;
    font-size: 1.5em;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    line-height: 1.1;
    font-size: 0.6em;
  }
  @media (min-width: 501px) and (max-width: 1000px) {
    font-size: 1.2em;
    text-align: justify;
  }
  @media (min-width: 1001px) and (max-width: 1150px) {
    font-size: 1.6em;
    text-align: justify;
    line-height: 1.3;
  }
}
.image-card {
  width: 100% !important;
}
.card_image2 {
  height: 350px !important;
  margin-top: 0px;
  @media (min-width: 100px) and (max-width: 500px) {
    margin-top: 10vw;
  }
}
.card_image3 {
  height: 350px !important;
  margin-top: 0px;
  @media (min-width: 100px) and (max-width: 500px) {
    margin-top: 10vw;
  }
  @media (min-width: 500px) and (max-width: 900px) {
    margin-top: 5vh;
    margin-bottom: 10vh;
  }
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 10px 0px 10px 0px;
}

.card.case-study-card {
  height: 100%;
  padding: 30px;
  border-radius: 1em;
  box-shadow: 0 4px 12px 0 rgba(44, 34, 34, 0.2);
  color: #fff;
  .article {
    width: 100%;
    height: 200px;
  }
  @media (max-width: 768px) {
    height: 80%;
    .article {
      height: 100px;
      // object-fit: cover;
    }
    .title-view {
      font-size: 12px;
      text-align: justify;
    }
  }  
  @media (min-width: 1201px) and (max-width: 1430px) {  
    .article {
      height: 90px;
      // object-fit: cover;
    }
    .title-view {
      font-size: 18px;
      text-align: justify;
    }
  }

}
.ant-layout-footer {
  background-color: #181818;
  margin-top: 0px;
  @media (min-width: 100px) and (max-width: 500px) {
    margin-bottom: 21%;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    margin-bottom: 10%;
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    margin-top: 15px;
  }
  @media (min-width: 1366px) and (max-width: 1800px) {
    margin-top: 25px;
  }
}
.link {
  color: white;
  @media (min-width: 100px) and (max-width: 500px) {
    font-size: 0.64em;
  }
}

.link:hover {
  color: rgb(22, 88, 116);
}
.footer {
  margin-top: 0%;
  @media (min-width: 100px) and (max-width: 500px) {
    margin-top: -17%;
  }
}
input:checked ~ .btnServices {
  background: #165874;
  border: #165874;
  color: whitesmoke;
}
.card-view {
  height: 500px;
  padding: 60px 40px 60px 160px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  @media (min-width: 100px) and (max-width: 907px) {
    height: 500px;
    padding: 47px 8px 52px 131px;
  }
  @media (min-width: 708px) and (max-width: 1300px) {
    height: 593px;
  }
  @media (min-width: 1301px) and (max-width: 1500px) {
    height: 573px;
  }  
}
.img-view {
  width: 500px;
  height: 350px;
  @media (min-width: 100px) and (max-width: 500px) {
    height: 200px;
    width: 200px;
  }
}
.imageDiv {
  position: relative;
  margin-right: -120px;
  flex: 0 0 auto;
  z-index: 3;
}
.firstDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: "column";
  padding: 10px;
  @media (min-width: 100px) and (max-width: 500px) {
    flex-direction: column;
    .img-view {
      width: 70%;
      position: relative;
      flex: 0 0 auto;
      z-index: -3;
    }
    .imageDiv {
      position: relative;
      margin-right: -120px;
      flex: 0 0 auto;
      // z-index: 3;
      margin-bottom: -30px;
    }
    .card-view {
      width: 100%;
    }
    .heading-copy {
      text-align: center;
      font-size: 15px;
      margin-left: -50%;
    }
    .para2-market {
      line-height: 1;
      font-size: 1em;
      text-align: justify;
      margin-left: -54%;
    }
  }
  @media (min-width: 500px) and (max-width: 900px) {
    flex-direction: column;
    .img-view {
      width: 90%;
      position: relative;
      flex: 0 0 auto;
      z-index: -3;
      margin-left: -10px;
    }
    .imageDiv {
      position: relative;
      margin-right: -120px;
      flex: 0 0 auto;
      // z-index: 3;
      margin-bottom: -30px;
    }
    .card-view {
      width: 100%;
    }
    .heading-copy {
      text-align: center;
      font-size: 20px;
      margin-left: -20%;
    }
    .para2-market {
      line-height: 2;
      font-size: 15px;
      text-align: justify;
      margin-left: -24%;
    }
  }
}
.head-view {
  font-size: 40px;
  font-family: serif;
  @media (min-width: 100px) and (max-width: 576px) {
    font-size: 17px;
  }
  @media (min-width: 578px) and (max-width: 736px) {
    font-size: 27px;
  }
}
.title-view {
  font-weight: bold;
  //  font-size: 30px;
  @media (min-width: 100px) and (max-width: 500px) {
    font-size: 16px;
  }
}
.desc-view {
  font-weight: bold;
  color: #000000;
  @media (min-width: 100px) and (max-width: 500px) {
    font-size: 10px;
  }
}
.pay-button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 40px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #165874;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
  margin-left: 33%;
}
.pay-button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #165874;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.pay-head {
  color: #32325d;
  font-weight: 400;
  line-height: 50px;
  font-size: 40px;
  margin: 20px 0;
  padding: 0;
}
.pay-label {
  color: #6b7c93;
  font-weight: 500;
  letter-spacing: 0.025em;
  text-align: center;
}
.pay-form {
  margin-bottom: 40px;
  padding-bottom: 40px;
  // border-bottom: 3px solid #e6ebf1;
}
// input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}
* {
  box-sizing: border-box;
}
.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}
.summary {
  font-weight: 700;
}
.testSummary {
  margin-left: 20px;
  width: 450px;
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: -30px;
    width: 307px;
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    margin-left: -2px;
    width: 371px;
  }
  @media (min-width: 901px) and (max-width: 1024px) {
    margin-left: -35px;
    width: 358px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    margin-left: -63px;
    width: 336px;
  }
}
.TotalAmt {
  font-weight: 700;
  margin-top: 10px;
}
.testsCost {
  float: right;
}
.login-fields {
  margin: 0px auto;
  width: 100%;
  height: auto;
  padding: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 10px;
  max-width: 500px;
  @media (max-width: 500px) {
    max-width: 90%;
  }
}
.admin-login-form {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .ant-row {
    width: 250px;
  }
}
.login-form-button {
  background-color: #949caa;
  border-color: #949caa;
  border-radius: 15px;
  float: right;
  //   font-family: avenirmedium;
  font-size: 15px;
  height: 35px;
  width: 100%;
  &:hover {
    border-color: #949caa;
    background-color: #949caa;
  }
}
.password-row {
  margin-top: 12px;
}
// .LoderButton .spinning.glyphicon {
//   margin-right: 7px;
//   top: 2px;
//   animation: spin 1s infinite linear;
// }
// @keyframes spin {
//   from { transform: scale(1) rotate(0deg); }
//   to { transform: scale(1) rotate(360deg); }
// }
.text-right {
  text-align: right;
}
// .email-view{
//   font-size: 15px;
//   @media (min-width: 100px) and (max-width: 500px) {
//    font-size: 10px;
//   }
// }
.checbox-step8 {
  margin-left: 90%;
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 71%;
  }
}
.progress-mobile {
  width: 170px;
  margin-left: 80%;
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 45%;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    margin-left: 70%;
  }
}

.logoStyle {
  justify-content: center;
  @media (min-width: 1200px) and (max-width: 1400px) {
    margin-left: 62%;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    margin-left: 62%;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-left: 155px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    margin-left: -4%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 10px;
  }
}

.logoImgStyle {
  width: 294px;
  height: 44px;
}

.cardImg6 {
  height: 300%;
  width: 100%;
  margin-left: 3px;
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 5px;
  }
}

.titleStyle {
  color: white;
  font-size: 20px;
  text-align: center;
}
.descStyle {
  color: white;
  text-align: justify;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
}
.custom-card {
  height: 100%;
  padding: 30px;
  // border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #054e6b;
  color: #fff;
}

.cardStyle {
  height: 500px; 
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: -16px;
    padding: 15px;
    height: 500px;
  }
  @media (min-width: 501px) and (max-width: 1000px) {
     height: 420px;
  }
  @media (min-width: 1001px) and (max-width: 1200px) {
    height: 433px;
  }
  @media (min-width: 1201px) and (max-width: 1300px) {
    height: 550px;
    margin-left: -5px;
  }
  @media (min-width: 1301px) and (max-width: 1500px) {
    height: 540px;
    margin-left: -5px;
  }
}

.mediaStyle {
  height: 500px;
  @media (min-width: 400px) and (max-width: 780px) {
    height: 329px;
    margin-bottom: -63px;
  }
  @media (min-width: 781px) and (max-width: 990px) {
    height: 406px;
    margin-bottom: 19px;
  }
  @media (min-width: 1201px) and (max-width: 1430px) {  
    height: 376px;
  }
}

.btnStyle {
  border-radius: 50px;
  float: left;
  height: 40px;
  background-color: #165874;
  width: 200px;
  margin-top: -16px;
  margin-bottom: 35px;
  @media (min-width: 501px) and (max-width: 767px) {
    margin-left: 50px;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    justify-content: center;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-top: 50px;
    margin-bottom: 95px;
  }
}

.mediaBtnstyle {
  border-radius: 50px;
  margin-left: 14px;
  @media (min-width: 708px) and (max-width: 1300px) {
    margin-left: 3%;
    margin-top: -3%;
  }
  // @media (min-width: 708px) and (max-width: 1400px) {
  //   margin-left: 25%;
  // }
  @media (min-width: 501px) and (max-width: 767px) {
    margin-left: 12%;
  }
  @media (min-width: 100px) and (max-width: 500px) {
    margin-left: 12%;
  }
}
.cardDiv {
  justify-content: center;
  align-items: center;
  margin-left: 15%;
  @media (min-width: 1000px) and (max-width: 1200) {
    margin-left: 5%;
  }
  @media (min-width: 1400px) and (max-width: 1600) {
    margin-left: 25%;
  }
}

.register-link {
  display: block;
  font-size: 18px;
  text-align: center;
  color: white;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.register-link:hover {
  color: rgb(49, 197, 242);
}
